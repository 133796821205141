<template>
    <div class="wrapper">
        <div class="container-fluid">
            <!-- Page-Title -->
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <h2 class="page-title">Data Balita</h2>
                    </div>
                </div>
            </div>
            <!-- end page title end breadcrumb -->
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-lg-3 col-sm-12">
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <div class="card overflow-hidden">
                                        <div class="card-heading bg-light">
                                            <input type="checkbox" id="filWilayah" v-model="isActive.wilayah"
                                                data-switch="default" @change="changeActive('wilayah', $event)"
                                                :checked="isActive.wilayah" />
                                            <label for="filWilayah" data-on-label="" data-off-label=""></label>
                                            <h3 class="card-title text-uppercase float-right">Filter Wilayah</h3>
                                            <p class="card-sub-title font-13 text-muted">Filter Balita Per Wilayah
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p class="m-t-0 header-title"><b>Kecamatan</b></p>
                                            <Select2 :disabled="!isActive.wilayah" v-model="filter.kecKode"
                                                :options="data.kecamatan" @select="selectKecamatan($event)" />
                                            <p class="header-title mt-2"><b>Desa</b></p>
                                            <Select2 :disabled="!isActive.wilayah" v-model="filter.kodeKab"
                                                :options="data.desa" @select="selectDesa($event)" />
                                            <p class="header-title mt-2"><b>Posyandu</b></p>
                                            <Select2 :disabled="!isActive.wilayah" v-model="filter.kdPosyandu"
                                                :options="data.posyandu" @select="selectPosyandu($event)" />
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <div class="card overflow-hidden">
                                        <div class="card-heading bg-light">
                                            <input type="checkbox" v-model="isActive.puskesmas" id="filPkm"
                                                data-switch="default" @change="changeActive('puskesmas', $event)"
                                                :checked="isActive.puskesmas" />
                                            <label for="filPkm" data-on-label="" data-off-label=""></label>
                                            <h3 class="card-title text-uppercase float-right">Filter Puskesmas</h3>
                                            <p class="card-sub-title font-13 text-muted">Filter Balita Per Puskesmas
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p class="header-title mt-2"><b>Puskesmas : {{ filter.pkmKode }}</b></p>
                                            <Select2 :disabled="!isActive.puskesmas" v-model="filter.pkmKode"
                                                :options="data.puskesmas" />
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <!--end row -->
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <div class="card overflow-hidden">
                                        <div class="card-heading bg-light">
                                            <h3 class="card-title text-uppercase">Filter Variable</h3>
                                        </div>
                                        <div class="card-body">
                                            <p class="m-t-0 header-title"><b>Kelompok Umur</b></p>
                                            <Select2 v-model="filter.kdKelUmur" :options="data.kelUmur"
                                                @select="filterKelUmur($event)" />
                                            <div class="form-group ml-1 mt-2">
                                                <div class="checkbox checkbox-custom">
                                                    <input v-model="filter.filGakin" value="1" id="gakin"
                                                        type="checkbox">
                                                    <label for="gakin">
                                                        Gakin
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <!--end row -->
                            <div class="row mb-2">
                                <div class="col-xl-12 col-sm-12">
                                    <label class="control-label">Tanggal Lahir</label>
                                    <input type="text" class="form-control" v-model="tgl_lahir" disabled>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <button @click="showData()"
                                        class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                        :disabled="is_loading"><span
                                            :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                            aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Tampilkan Data"
                                            }}</button>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-9 col-sm-12">
                            <div class="card overflow-hidden bg-light">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <p class="m-t-0 header-title"><b>Status</b></p>
                                            <Select2 v-model="filter.status" :options="data.status" />
                                        </div>
                                        <div class="col-md-2">
                                            <p class="m-t-0 header-title"><b>JK</b></p>
                                            <div class="input-group">
                                                <span class="input-group-prepend">
                                                    <button type="button" @click="setOrderJK('ASC')"
                                                        class="btn waves-effect waves-light"
                                                        :class="{ 'btn-custom': isActive.btn == 'ASC' }"><i
                                                            class="mdi mdi-arrow-up-bold-outline lg"></i></button>
                                                </span>
                                                <Select2 v-model="filter.jk" :options="data.jk" />
                                                <span class="input-group-append">
                                                    <button type="button" @click="setOrderJK('DESC')"
                                                        class="btn waves-effect waves-light"
                                                        :class="{ 'btn-custom': isActive.btn == 'DESC' }"><i
                                                            class="mdi mdi-arrow-down-bold-outline"></i></button>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <p class="m-t-0 header-title"><b>BBU</b></p>
                                            <Select2 v-model="filter.bbu" :options="data.bbu" />
                                        </div>
                                        <div class="col-md-2">
                                            <p class="m-t-0 header-title"><b>TBU</b></p>
                                            <Select2 v-model="filter.tbu" :options="data.tbu" />
                                        </div>
                                        <div class="col-md-2">
                                            <p class="m-t-0 header-title"><b>BBTB</b></p>
                                            <Select2 v-model="filter.bbtb" :options="data.bbtb" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card overflow-hidden">
                                <div class="card-heading bg-light">
                                    <h3 class="card-title text-uppercase">Data Balita</h3>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="tb-balita" class="table table-striped table-bordered nowrap"
                                            style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Akte</th>
                                                    <th>NoKK</th>
                                                    <th>Nama</th>
                                                    <th>Tgl Lahir</th>
                                                    <th>Umur</th>
                                                    <th>JK</th>
                                                    <th>Nama Ibu</th>
                                                    <th>Nama Ayah</th>
                                                    <th>Gakin</th>
                                                    <th>Posyandu</th>
                                                    <th>RT</th>
                                                    <th>RW</th>
                                                    <th>Dusun</th>
                                                    <th>Desa</th>
                                                    <th>TB Terakhir</th>
                                                    <th>BB Terakhir</th>
                                                    <th>Tgl Timbang</th>
                                                    <th>Stunting</th>
                                                    <th>IDL</th>
                                                    <th>BADUTA</th>
                                                    <th>BBU</th>
                                                    <th>TBU</th>
                                                    <th>BBTB</th>
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End container-fluid -->
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from '@/views/layouts/FooterComponent.vue';
import { useToast } from "vue-toastification";
import axios from 'axios';
import Select2 from 'vue3-select2-component';
import { ref, onMounted } from 'vue';

export default {
    name: 'Home',
    components: {
        Footer,
        Select2
    },
    setup() {
        const date = ref();
        const toast = useToast();

        onMounted(() => {
            const startDate = new Date();
            const endDate = new Date(new Date().setDate(startDate.getDate()));
            date.value = [startDate, endDate];
        })

        return {
            date,
            toast
        }
    },
    data() {
        return {
            balita: [],
            table: null,
            tgl_lahir: '',
            isActive: {
                puskesmas: false,
                wilayah: true,
                periode: false,
                btn: 'ASC'
            },
            is_loading: false,
            filter: {
                pkmKode: '',
                kecKode: '',
                kodeKab: 'all',
                kdPosyandu: 'all',
                filGakin: false,
                dateStart: '',
                dateEnd: '',
                kdKelUmur: '',
                status: 'Masih Menetap',
                jk: 'L/P'
            },
            data: {
                puskesmas: [],
                kelUmur: [],
                kecamatan: [],
                desa: [],
                posyandu: [{ id: 'all', text: '- Semua Posyandu -' }],
                gakin: [
                    { id: '000', text: 'Semua balita' },
                    { id: '0', text: 'Non Gakin' },
                    { id: '1', text: 'Gakin' }
                ],
                status: [
                    { id: 'Masih Menetap', text: 'Masih Menetap' },
                    { id: 'Pindah', text: 'Pindah' },
                    { id: 'Meninggal', text: 'Meninggal' }
                ],
                jk: [
                    { id: 'L/P', text: 'L/P' },
                    { id: 'L', text: 'L' },
                    { id: 'P', text: 'P' }
                ],
                bbu: [{ id: 'all', text: 'Semua' }],
                tbu: [{ id: 'all', text: 'Semua' }],
                bbtb: [{ id: 'all', text: 'Semua' }]
            }
        };
    },
    methods: {
        async getPuskesmas() {
            await axios.get(`dasar/puskesmas`)
                .then(response => {
                    this.data.puskesmas = response.data.content.puskesmas.map(pkm => (
                        { id: pkm.Kode, text: pkm.Puskesmas.toUpperCase() }
                    ));
                    this.filter.pkmKode = this.data.puskesmas[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getKecamatan() {
            await axios.get(`dasar/kecamatan`)
                .then(response => {
                    this.data.kecamatan = response.data.content.kecamatan.map(kec => (
                        { id: kec.Kode_Kecamatan, text: kec.Kecamatan.toUpperCase() }
                    ));
                    this.filter.kecKode = this.data.kecamatan[0].id;
                    this.getDesa();
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getDesa() {
            await axios.get(`dasar/desa/` + this.filter.kecKode)
                .then(response => {
                    var desa = response.data.content.desa.map(item => (
                        { id: item.KodeKab, text: item.Desa.toUpperCase() }
                    ));

                    var defDesa = [{ id: 'all', text: '- Semua -' }];
                    this.data.desa = [...defDesa, ...desa];
                    this.filter.kodeKab = this.data.desa[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getPosyandu() {
            await axios.get(`dasar/posyandu/` + this.filter.kodeKab)
                .then(response => {
                    var posyandu = response.data.content.posyandu.map(item => (
                        { id: item.Kode_Posyandu, text: item.Posyandu.toUpperCase() }
                    ));

                    var defPosyandu = [{ id: 'all', text: '- Semua Posyandu -' }];
                    this.data.posyandu = [...defPosyandu, ...posyandu];
                    this.filter.kdPosyandu = this.data.posyandu[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getKelompokUmur() {
            await axios.get(`balita/kelompokUmur`)
                .then(response => {
                    this.data.kelUmur = response.data.content.umur.map(item => (
                        {
                            id: item.Kode,
                            text: item.Kelompok,
                            start: item.TanggalAwal,
                            end: item.TanggalAkhir
                        }
                    ));

                    this.filter.kdKelUmur = this.data.kelUmur[0].id;
                    this.dateStart        = this.data.kelUmur[0].start;
                    this.dateend          = this.data.kelUmur[0].end;
                    this.tgl_lahir        = `${this.data.kelUmur[0].start.split("-").reverse().join("/")} - ${this.data.kelUmur[0].end.split("-").reverse().join("/")}`;
                })
                .catch(e => {
                    console.log(e);
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getGizi() {
            await axios.get(`balita/gizi`)
                .then(response => {
                    var tbu = response.data.content.tbu.map(item => (
                        { id: item.Kode, text: item.Status_Gizi }
                    ));
                    var defTbu = [{ id: 'all', text: 'Semua' }];
                    this.data.tbu = [...defTbu, ...tbu];
                    this.filter.tbu = this.data.tbu[0].id;

                    var bbu = response.data.content.bbu.map(item => (
                        { id: item.Kode, text: item.Status_Gizi }
                    ));
                    var defBbu = [{ id: 'all', text: 'Semua' }];
                    this.data.bbu = [...defBbu, ...bbu];
                    this.filter.bbu = this.data.bbu[0].id;

                    var bbtb = response.data.content.bbtb.map(item => (
                        { id: item.Kode, text: item.Status_Gizi }
                    ));
                    var defBbtb = [{ id: 'all', text: 'Semua' }];
                    this.data.bbtb = [...defBbtb, ...bbtb];
                    this.filter.bbtb = this.data.bbtb[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        selectKecamatan(event) {
            if (event.id !== '') {
                this.getDesa();
            }
        },
        selectDesa(event) {
            if (event.id !== '') {
                this.getPosyandu();
            }
        },
        setOrderJK(action) {
            this.isActive.btn = action;
        },
        filterKelUmur(event) {
            this.dateStart = event.start;
            this.dateend = event.end;
            this.tgl_lahir = `${event.start.split("-").reverse().join("/")} - ${event.end.split("-").reverse().join("/")}`;
        },
        async showData() {
            this.is_loading = true;
            await axios({
                method: 'GET',
                url: 'balita',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    'kdPkm': (this.isActive.puskesmas) ? this.filter.pkmKode : '',
                    'kdKecamatan': (this.isActive.wilayah) ? this.filter.kecKode : '',
                    'kdDesa': (this.isActive.wilayah && this.filter.kodeKab !== 'all') ? this.filter.kodeKab : '',
                    'kdPosyandu': (this.isActive.wilayah && this.filter.kdPosyandu !== 'all') ? this.filter.kdPosyandu : '',
                    'gakin': (this.filter.filGakin) ? '1' : '0',
                    'dobStart': this.dateStart,
                    'dobEnd': this.dateEnd,
                    'status': this.filter.status,
                    'shortJK': this.isActive.btn,
                    'bbu': (this.filter.bbu !== 'all') ? this.filter.bbu : '',
                    'tbu': (this.filter.tbu !== 'all') ? this.filter.tbu : '',
                    'bbtb': (this.filter.bbtb !== 'all') ? this.filter.bbtb : ''
                }
            })
                .then(response => {
                    this.is_loading = false;
                    this.balita = response.data.content.balita;
                    $('#tb-balita').DataTable().destroy();
                })
                .catch(e => {
                    this.is_loading = false;
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        changeActive(current) {
            if (current == 'wilayah') {
                this.isActive.puskesmas = false;
                this.isActive.wilayah = true;
            } else {
                this.isActive.wilayah = false;
                this.isActive.puskesmas = true;
            }
        }
    },
    watch: {
        balita() {
            $('#tb-rekap-bumil').DataTable().destroy();
            $('#tb-balita').DataTable({
                processing: true,
                destroy: true,
                scrollX: true,
                orders: [],
                data: this.balita,
                columns: [
                    { data: "No_Index_Balita_Dinas" },
                    {
                        data: "Akte",
                        render: function (data) {
                            return (data == '0') ? 'Belum' : 'Sudah';
                        }
                    },
                    { data: "NoKK" },
                    { data: "Nama_Bayi" },
                    { data: "Tanggal_Lahir" },
                    { data: "Umur" },
                    { data: "Jenis_Kelamin" },
                    { data: "Nama_Ibu" },
                    { data: "Nama_Ayah" },
                    { data: "Gakin" },
                    { data: "posyandu" },
                    { data: "RT" },
                    { data: "RW" },
                    { data: "Dusun" },
                    { data: "Desa" },
                    { data: "TB_Terakhir" },
                    { data: "BB_Terakhir" },
                    { data: "Tanggal_Timbang_Terakhir" },
                    { data: "stunting" },
                    { data: "cekidl" },
                    { data: "cekbaduta" },
                    { data: "Status_BBU" },
                    { data: "Status_TBU" },
                    { data: "Status_BBTB" },
                ],
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Menampilkan halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
            });
        },
        $route(to, from) {
            $('#tb-balita').DataTable().clear().draw();
            $('#tb-balita').DataTable().destroy();
        }
    },
    async created() {
        await this.getPuskesmas();
        await this.getKecamatan();
        await this.getKelompokUmur();
        await this.getGizi();
        await this.$nextTick(function () {
            $('#tb-balita').DataTable();
        });
    }
}
</script>

<style>

</style>